import bbox from "@turf/bbox";

import { lineString } from "@turf/helpers";
import { last } from "lodash";
import mapboxgl from "mapbox-gl";
import React, { useRef, useState } from "react";
import { Layer, Map as ReactMap, Source } from "react-map-gl";
import { Composition, continueRender, delayRender } from "remotion";
import { getGeocodes } from "utils/getters";
import "./TripReel.scss";
import currentTripJSON from "./currentTrip.json";
import ImageMarker from "./imagemarker/ImageMarker";
import reelDataJSON from "./reelData.json";
const INTRO = 100;
const FLYTOSTART = 40;
const MAP_DURATION = 40;
const PHOTO_DURATION = 20;

mapboxgl.accessToken = "pk.eyJ1Ijoib21hcnMiLCJhIjoiY2w0NDN3OHRvMDAxazNkcXRzcG1kMmpyMCJ9.l6IL4HDt1K5dfcDAFnp-vg";

const CompositionComponent = ({ currentTrip, reelData }) => {
  const mapRef = useRef(null);
  const [handle] = useState(() => delayRender("Loading map..."));
  // const frame = useCurrentFrame();
  // const ref = useRef<HTMLDivElement>(null);
  // const [bound, setBound] = useState({});
  // useEffect(() => {
  //   const viewport = new WebMercatorViewport({ width: 3510, height: 2490 });
  //   const polygon = lineString(currentTrip?.destinations?.map(getGeocodes));
  //   const [minLng, minLat, maxLng, maxLat] = bbox(polygon);
  //   const bound = viewport.fitBounds(
  //     [
  //       [minLng, minLat],
  //       [maxLng, maxLat],
  //     ],
  //     { padding: 20, offset: [0, -40] }
  //   );
  //   console.log(bound);
  //   setBound(bound);
  //   setViewState({
  //     longitude: bound?.longitude,
  //     latitude: bound?.latitude,
  //   });
  // }, []);

  // const [viewState, setViewState] = useState({
  //   longitude: 0,
  //   latitude: 0,
  // });

  // console.log(viewState);
  // console.log(bound.zoom);

  // const { fps } = useVideoConfig();
  // const [handle] = useState(() => delayRender("Loading map..."));
  // const map = mapRef?.current?.getMap();

  const paths = reelData?.map((item) => {
    return item?.path?.length > 0 ? lineString(item?.path) : null;
  });

  const markerLocations = reelData?.map((item, index) => {
    const endDestination: any = last(reelData?.[reelData?.length - 2]?.path) || [];
    const coords = item?.path?.length > 0 ? item?.path?.[0] : endDestination?.length > 0 ? endDestination : null;
    return {
      image: item?.photos?.[0],
      lng: coords?.[0],
      lat: coords?.[1],
    };
  });

  const fitMap = () => {
    if (currentTrip?.destinations?.length > 0) {
      const polygon = lineString(currentTrip?.destinations?.map(getGeocodes));
      const [minLng, minLat, maxLng, maxLat] = bbox(polygon);
      mapRef?.current?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 400, duration: 4000 }
      );
      if (mapRef?.current?.getMap()._fullyLoaded) {
        setTimeout(() => {
          continueRender(handle);
          console.log("loaded");
        }, 2000);
      }
    }
  };

  const paint = {
    "line-color": "#FFFFFF",
    "line-opacity": 1,
    "line-width": 8,
  };

  return (
    <>
      <ReactMap
        // {...viewState}
        ref={mapRef}
        mapStyle="mapbox://styles/omars/cm232jalo005601pgal7x1emq"
        //@ts-ignore
        projection="mercator"
        style={{ height: "100%", width: "100%" }}
        mapboxAccessToken="pk.eyJ1Ijoib21hcnMiLCJhIjoiY2w0NDN3OHRvMDAxazNkcXRzcG1kMmpyMCJ9.l6IL4HDt1K5dfcDAFnp-vg"
        reuseMaps={true}
        attributionControl={false}
        // onLoad={fitMap}
        onIdle={fitMap}
      >
        {paths?.map((path, index) => (
          <Source id={`source-${index}`} type="geojson" data={path} lineMetrics={true}>
            <Layer id={`line-${index}`} type="line" source={`source-${index}`} paint={paint} />
          </Source>
        ))}

        {markerLocations?.map((marker, index) => (
          <ImageMarker key={index} destination={marker} />
        ))}
      </ReactMap>
    </>
  );
};

const MapImage: React.FC = () => {
  const currentTrip = currentTripJSON;
  const reelData = reelDataJSON;
  return (
    <Composition
      //@ts-ignore
      component={CompositionComponent}
      fps={30}
      durationInFrames={201}
      id="reel"
      width={3510}
      height={2490}
      defaultProps={{ currentTrip, reelData }}
    />
  );
};

export default MapImage;
