import { Marker } from "react-map-gl";
import "./ImageMarker.scss";

const ImageMarker = ({ destination }) => {
  return (
    <Marker longitude={destination.lng} latitude={destination.lat}>
      <div className={`image-destination-marker ${!destination?.image ? "no-image" : ""}`}>
        {destination?.image ? <img src={`https://media.stippl.io/${destination?.image}`} /> : null}
      </div>
    </Marker>
  );
};

export default ImageMarker;
